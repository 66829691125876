<template>
  <component
    :is="isInternal ? 'nuxt-link' : 'a'"
    v-if="cta.href !== ''"
    :href="cta.href"
    :to="isInternal && cta.href"
    class="shadow-md flex flex-col transform bg-white hover:shadow-lg hover:-translate-y-1 transition-all duration-300 ease no-underline"
    :target="!isInternal && promo.button.target"
    :rel="!isInternal && 'noopener noreferrer'"
  >
    <static-card :card-shadow="false" class="flex-1 stc">
      <template #card-header>
        <h3
          class="h5 px-4 md:px-5 pt-6 md:pt-8 -mb-2 leading-tight"
          :class="getCssClass('', 'text-int-grey-01')"
        >
          {{ promo.title.value }}
          <span :class="`text-brand-${promo.subtitleColour.value}-700`">{{
            promo.subtitle.value
          }}</span>
        </h3>
      </template>
      <div v-html="$md.render(promo.description.value)" />
      <template #card-image>
        <div
          v-if="promo.image.src"
          class="aspect-w-16 aspect-h-9 relative"
          :class="
            getCssClass('', [
              'border-b-6',
              `border-int-${promo.subtitleColour.value}-02`
            ])
          "
        >
          <lazy-img
            src="/images/cpn.png"
            :data-src="imgSource"
            class="object-cover w-full h-full"
            :alt="promo.image.alt"
          />
        </div>
      </template>
      <template #cardFooter>
        <A11yButton component="div" variant="primary" class="mt-auto w-full">
          <span class="mr-auto">
            {{ promo.button.text }}
          </span>
          <Icon name="IconChevronRight" class="text-2xl" />
        </A11yButton>
      </template>
    </static-card>
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import useConstants from '~/composables/useConstants'
import useHelpers from '~/composables/useHelpers'
import useTheme from '~/composables/theme/useTheme'

const { getImgSrc } = useHelpers()
const { sitecore } = useConstants()
const { getCssClass } = useTheme()

const props = defineProps({
  promo: {
    type: Object,
    default() {},
    required: true
  },
  index: {
    type: Number,
    default: 0
  }
})

const cta = computed(() => {
  return {
    href: isInternal.value
      ? props.promo.button.url
          .replace(sitecore.internalLinkspath, '')
          .toLowerCase()
      : props.promo.button.url.toLowerCase()
  }
})
const isInternal = computed(() => {
  return props.promo.button.linkType === sitecore.linkTypeInternal
})
const imgSource = computed(() => {
  return getImgSrc(props.promo.image.src)
})
</script>
